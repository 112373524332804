import './App.css';
import "./assets/css/custom.css"
import AppRoutes from './AppRoutes';

export default function App() {
  return (
    <div id='app-root'>
      <AppRoutes />
      
    </div>
  );
}